import { setCall, handleResponse } from '../_helpers';

export const actorsServices = {
    licenseValidate
}

async function licenseValidate(license) {
    const requestOptions = {
      method: 'POST',
      body: setCall('actors', 'license_validate', {license}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}