import React, { useState, useEffect, Fragment } from 'react';
import { Popup, Tooltip } from 'react-leaflet'
import L from 'leaflet';
import styled from "styled-components";
import { arrows } from '../_constants/arrows_sizes'
import 'leaflet-rotatedmarker';
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"

//import { useMap } from 'react-leaflet'

import { shipData } from '../_helpers/utils'

import ShipCard from './ShipCard';
import { BrowserView } from 'react-device-detect';

function createShipIcon(ship, zoom, arrows, imagesUrl){
    const shipType = ship.ship_type;
    const shipSpeed = ship.sog;
    const iconName = 'arrow_0.png';
    var iconUrl = '';
    var iconWidht = '';
    var iconHeight = '';

    if (shipSpeed <= 0.5 || !arrows[iconName]){
        iconUrl = imagesUrl+'/images/static/static_'+shipType.color+'.png';
        iconWidht = 100/(20-zoom)//(arrows[iconName]['width']+1)/(20-zoom)
        iconHeight = 100/(20-zoom)//(arrows[iconName]['height']+1)/(20-zoom)
    }
    else{
        iconUrl = imagesUrl+'/images/arrows/'+shipType.color+'/'+iconName;
        iconWidht = (arrows[iconName]['width']+1)/(20-zoom)//*(0.1*ship.width)
        iconHeight = (arrows[iconName]['height']+1)/(20-zoom)//*(0.1*ship.length)
    }

    const CustomIcon = L.icon({
        iconUrl: iconUrl,
        iconSize:     [iconWidht,iconHeight], // size of the icon
        iconAnchor:   [10, 1], // point of the icon which will correspond to marker's location
    });
    return CustomIcon
}


const NameOnlyTooltip = styled(Tooltip)`  
    padding: 3px;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.5);
    .leaflet-popup-tip {
        border: 0px;
    }
`;

const DescriptionTooltip = styled(Tooltip)`  
    padding: 3px;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0.7);
    .leaflet-popup-tip {
        border: 0px;
    }
`;

const ShipPopup = styled(Popup)`  
    padding: 0px;
    font-size: 10px;
    font-weight: bold;
    .leaflet-popup-tip {
        border: 0px;
    }
    .leaflet-popup-content-wrapper {
        padding: 15px 0px 0px;
        margin: 0px;
        text-align: center;
        border: 1px solid #fff;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 1px;
    }
`;

export default function Ship(props) {
    const { dic, ship, zoom, setSelectedShip, markerRefs, done, setDone, ships, index, imagesUrl } = props;
    const [actualShip, setActualShip] = useState(false)
    
    useEffect(() => {
        const aS = shipData(ship);
        
        setActualShip({...aS, icon: createShipIcon(aS, zoom, arrows, imagesUrl)})
        
        if (markerRefs.current[aS.mmsi]){
            
            markerRefs.current[aS.mmsi].setIcon(createShipIcon(aS, zoom, arrows, imagesUrl))
            if (aS.sog > 0.5)
                markerRefs.current[aS.mmsi].setRotationAngle(aS.cog)
            
        }
    },[ship, markerRefs, zoom, imagesUrl])
    
    

    return (
        <Fragment>
            {actualShip &&
                <ReactLeafletDriftMarker
                    ref={(m) => {
                        markerRefs.current[actualShip.mmsi] = m;
                        if (index === ships.length - 1 && !done) {
                            setDone(true);
                        }
                      }
                    }
                    position={actualShip.position}
                    duration={1000}
                    icon={actualShip.icon}
                    rotationAngle={actualShip.sog > 0.5 ? actualShip.cog : 0}
                    eventHandlers={{
                        click: () => {
                            setSelectedShip({mmsi: actualShip.mmsi, position: actualShip.position})
                        },
                        popupclose: () => {
                            setSelectedShip(false)
                        },
                    }}
                >
                    <ShipPopup offset={[0, -15]}>
                        <ShipCard
                            ship={actualShip}
                            dic={dic}
                    />
                    </ShipPopup>
                    <BrowserView>
                    {zoom < 15  ?
                    <DescriptionTooltip offset={[20, 5]} opacity={0.8} direction="right">
                        {actualShip.name}<br />
                        {dic.SOG}: {actualShip.sog} kn<br />
                        {dic.COG}: {actualShip.cog}°<br />
                    </DescriptionTooltip>
                    :
                    <NameOnlyTooltip offset={[-10, 5]} opacity={0.8} permanent direction="left">
                        {actualShip.name}
                    </NameOnlyTooltip>
                    }</BrowserView>
                </ReactLeafletDriftMarker>
            }
        </Fragment>
    );
}