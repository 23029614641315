import { setCall, handleResponse } from '../_helpers';

export const usersServices = {
    login, isLogged, logout,
}

async function login(authData) {
    const requestOptions = {
      method: 'POST',
      body: setCall('users', 'login', authData),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}

async function isLogged() {
    const requestOptions = {
      method: 'POST',
      body: setCall('users', 'is_logged', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}

async function logout() {
    const requestOptions = {
      method: 'POST',
      body: setCall('users', 'logout', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}
