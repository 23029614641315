export function shipData(ship){
    let data = {...ship}
    data.position = shipCoordinates(ship);
    data.ship_type = getShipType(Number(ship.ship_type))
    data.sog = Number(ship.sog) > 0 ? Number(ship.sog)/10 : 0
    data.cog = Number(ship['cog'].substr(0, ship['cog'].length-1))
    data.draught = Number(ship.draught) > 0 ? Number(ship.draught)/10 : 0
    return data
}

function getShipType(shipTypeId){
	var color = 'yellow';
	var type = 'Non specificato';
	var it = Math.floor(shipTypeId/10);
	switch (it) {
		case 7:
			color = "green";
			type = "Cargo Vessel";
			break;
		case 8:
			color = "red";
			type = "Tanker"
			break;
		case 4:
			color = "blue";
			type = "High Speed Craft";
			break;
		case 6:
			color = "white";
			type = "Passenger"
			break;
		default:
			color = 'yellow';
			type = 'Non specificato';
			break;
	}
	if (shipTypeId === 52){
		color = "black";
		type = "Tug";
	}
	if (shipTypeId === 36 || shipTypeId === 37){
		color = "purple";
		type = "Pleasure Craft or Sailing Vessel";
	}
	if (shipTypeId > 99){
		color = "yellow";
		type = "Non specificato";
	}
 	var type_definition = [];
	type_definition.color = color;
	type_definition.label = type;
	return type_definition;
}

function shipCoordinates(ship){
    const position = [ship.lt, ship.lg]
    return position
}


export function isJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// funzione che gestisce tutte le risposte delle call all'API
export function handleResponse(response, type = null) {
    if (response.status === 200){
        if (type !== null)
            return response.blob()
        
        return response.text().then(text => {
            if (isJSON(text)){
                const data = text && JSON.parse(text);
                
                return data;
            }
            else{
                console.log(text);
                return {header: {result: 'connection'}, response: {details: {}}};
            }
        });
    }
    else{
        return response.text().then(text => {
            console.log('TEXT: '+ text);
        })
        .catch(error => {
            console.log('CATCH: '+error);
        });
    }
}

export function setCall(req_library, req_function, req_query, req_data){
    let initStruct = {
        header: {
            library: req_library,
            function: req_function,
            type: req_data ? req_data : 'data'
        },
        query: req_query
    }
    return JSON.stringify(initStruct);
}

export const formatDate = (date) => {
    var tmp = date.split("-")
    return tmp[2]+'/'+tmp[1]+'/'+tmp[0]
}

export const getToday = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
}

export const getTodayPlusDays = (days) => {
    const date = new Date();
    const new_date = new Date();
    new_date.setDate(date.getDate()+days);
    return new_date.toISOString().split('T')[0];
}

export const currencyFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
});

export const numberFormatter = new Intl.NumberFormat('it-IT')