import React, { Fragment, useState } from 'react';
import { MapContainer, TileLayer, LayersControl, LayerGroup } from 'react-leaflet'
//import { NmScale } from '@marfle/react-leaflet-nmscale';
import Control from 'react-leaflet-custom-control'

import "leaflet/dist/leaflet.css";
import MapMoveHandler from './MapMoveHandler';
import MobileMapMoveHandler from './MobileMapMoveHandler'
import Roadsteads from './Roadsteads';
import Ships from './Ships';
import { BrowserView, MobileView } from 'react-device-detect';
import Logout from './Logout';

export default function Map(props) {
    const { dic, roadsteads, position, imagesUrl, setIsLogged } = props

    const [zoom, setZoom] = useState(11)
    const [bounds, setBounds] = useState(false);
    const [cursorPosition, setCursorPoisition] = useState({lt: 0, lg: 0})
    const [lastBoundsChange, setLastBoundsChange] = useState(Date.now())
    const [mapRef, setMapRef] = useState(null)

    return (
        <Fragment>
            <div id="map" style={{height: '100%', width: '100%', position: 'fixed'}}>
                <MapContainer
                    center={position}
                    zoom={zoom}
                    scrollWheelZoom={false}
                    style={{height: '100%', width: '100%', position: 'relative'}}
                    whenReady={ mapInstance => { setMapRef(mapInstance) } }
                    tap={true}
                >
                    
                   
                        <Ships
                            dic={dic}
                            zoom={zoom}
                            bounds={bounds}
                            mapRef={mapRef}
                            imagesUrl={imagesUrl}
                            setIsLogged={setIsLogged}
                        />
                    <Control prepend position='bottomright' style={{backgroundColor: "#627ea8"}}>
                        <Logout
                            dic={dic}
                            setIsLogged={setIsLogged}
                        />
                    </Control>
                    <LayersControl>
                        <LayersControl.BaseLayer name="chiara">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="scura">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="stradale" checked={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.Overlay name="OpenSeaMap" checked={true}>
                            <LayerGroup>
                                <TileLayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" />
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </LayersControl>
                    {(roadsteads && zoom > 10) &&
                        <Roadsteads
                            roadsteads={roadsteads}
                        />
                    }
                    <BrowserView>
                        <MapMoveHandler
                            setBounds={setBounds}
                            setZoom={setZoom}
                            setCursorPoisition={setCursorPoisition}
                            lastBoundsChange={lastBoundsChange}
                            setLastBoundsChange={setLastBoundsChange}
                        />
                    </BrowserView>
                    <MobileView>
                        <MobileMapMoveHandler
                            setBounds={setBounds}
                            setZoom={setZoom}
                            lastBoundsChange={lastBoundsChange}
                            setLastBoundsChange={setLastBoundsChange}
                        />
                    </MobileView>
                    
                    <BrowserView>
                        <Control prepend position='bottomleft'>
                            <div>{cursorPosition.lg}, {cursorPosition.lt}</div>
                        </Control>
                    </BrowserView>
                </MapContainer>
            </div>
        </Fragment>
  );
}