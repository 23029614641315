import { useMapEvents } from 'react-leaflet'

export default function MobileMapMoveHandler(props) {
    const { setBounds, setZoom, lastBoundsChange, setLastBoundsChange } = props
    
    const map = useMapEvents({
        moveend: () => {
            let newBoundsChange = Date.now();
            let difference = newBoundsChange-lastBoundsChange;
            // se è passato un secondo dall'ultimo cambiamento di stato
            if (difference > 1000){
                setBounds({
                    n: map.getBounds().getNorth(),
                    e: map.getBounds().getEast(),
                    w: map.getBounds().getWest(),
                    s: map.getBounds().getSouth()
                });
                setZoom(map.getZoom())
                setLastBoundsChange(newBoundsChange)
            }
        }
    })
    return null
}