export const dic = {
	SHIP_TYPE: "Tipo di nave",
	FLAG: "Bandiera",
	SOG: "Sog",
	COG: "Cog",
	ETA: "Eta",
	DATA: "Dati",
	SEARCH: "Cerca",
	SHIPS: "Navi",
	SHIP: "Nave",
	AIS: "AIS",
	MMSI: "Mmsi",
	IMO: "Imo",
	CALLSIGN: "Callsign",
	LENGTH: "Lunghezza",
	WIDTH: "Larghezza",
	GROSS_TONNAGE: "Stazza lorda",
	DRAUGHT: "Pescaggio",
	ACTIVE: "Attivo",
	YES: "si",
	NO: "no",
	LOGOUT: "Esci",
}