import React, { Fragment } from 'react';
import {
    Card, CardHeader, CardContent, 
    Typography, Grid, Avatar
} from '@mui/material';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

export default function ShipRates(props) {
    const { ship, dic, title, isAis } = props;
    
    return (
        <Card square={true} style={{height: '100%'}}>
            <CardHeader
                title={title}
                subheader={ship.name}
                avatar={
                    <Avatar>
                      {isAis ? <GpsFixedIcon /> : <DirectionsBoatIcon />}
                    </Avatar>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.MMSI}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.mmsi}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.IMO}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.imo}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.CALLSIGN}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.callsign}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.FLAG}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.flag.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.LENGTH}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.length} m</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2">{dic.WIDTH}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="subtitle2" gutterBottom>{ship.width} m</Typography>
                            </Grid>
                            {isAis &&
                                <Fragment>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2">{!isAis ? dic.GROSS_TONNAGE : 'Max. vel. rilevata'}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="subtitle2" gutterBottom>{!isAis ? ship.gross_tonnage+" t" : ship.max_sog+" kn"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2">{isAis ? dic.DRAUGHT : dic.ACTIVE}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="subtitle2" gutterBottom>{isAis ? ship.draught+" m" : (ship.active > 0 ? dic.YES : dic.NO)}</Typography>
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                </Typography>
            </CardContent>
        </Card>
    );
}