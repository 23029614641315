import * as React from 'react';
import {
    Tooltip, Card, CardHeader, CardContent, CardActions, 
    Avatar, Typography, Grid
} from '@mui/material';
import WifiIndicator from 'react-wifi-indicator'; 

import ShipInfo from './ShipInfo';


const checkMinutesFromlastDetection = (timestamp) => {
    const date1 = Date.now();
    const date2 = Date.parse(timestamp);
    
    return (Math.abs(Math.round((date1 - date2)/1000/60)));
}

const signalQuality = (minutes) => {
    if (minutes <= 1)
        return 'EXCELLENT';
    if (minutes <= 3)
        return 'GREAT';
    if (minutes <= 5)
        return 'OKAY';
    if (minutes <= 10)
        return 'WEAK';
    if (minutes <= 15)
        return 'UNUSABLE';
    if (minutes > 15)
        return 'DISCONNECTED';
}

export default function ShipCard(props) {
    const { dic, ship } = props
    
    const minutesFromlastDetection = checkMinutesFromlastDetection(ship.timestamp)
    
    

    return (
        <Card sx={{ maxWidth: 245 }} style={{backgroundColor: "rgba(255, 255, 255, 0.0)", border: "none", boxShadow: "none"}} square={true}>
            <CardHeader
                title={ship.name}
                subheader={ship.length+"m. x "+ship.width+"m. x "+ship.draught+"m."}
                style={{
                    padding: 2,
                    textAlign: 'left',
                    backgroundColor: "rgba(255, 255, 255, 0.8)"
                }}
            />
            <CardContent style={{
                margin: 0,
                padding: 2,
                paddingTop: 1,
                paddingBottom: 1,
                textAlign: 'left',
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                width: 245
            }}>
                <Typography variant="body2" color="text.secondary">
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            {dic.SOG}
                        </Grid>
                        <Grid item xs={8}>
                            {ship.sog} kn
                        </Grid>
                        <Grid item xs={4}>
                            {dic.COG}
                        </Grid>
                        <Grid item xs={8}>
                            {ship.cog} °
                        </Grid>
                        <Grid item xs={4}>
                            {dic.ETA}
                        </Grid>
                        <Grid item xs={8}>
                            {ship.converted_eta}<br />[{ship.dest}]

                    
                        </Grid>
                    </Grid>

                </Typography>
            </CardContent>
            <CardActions 
                disableSpacing
                style={{
                    padding: 2,
                    paddingTop: 1,
                    textAlign: 'left',
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
            >
                <ShipInfo
                    ship={ship}
                    dic={dic}
                />
                <Tooltip title={"Rilevato "+minutesFromlastDetection+' minut'+(minutesFromlastDetection < 2 ? 'o' : 'i')+' fa'}>
                    <div style={{padding: 0}}>
                        <WifiIndicator
                            strength={signalQuality(minutesFromlastDetection)}
                            style={{
                                height: 20,
                                border: '0.1px solid lightgray',
                                borderRadius: 20,
                                padding: 2,
                            }}
                        />
                    </div>
                </Tooltip>
        </CardActions>
    </Card>
  );
}