import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet'
import { useSnackbar  } from 'notistack';

import { aisServices } from '../_services'
import Ship from './Ship';
import Control from 'react-leaflet-custom-control'
import SearchDialog from './SearchDialog';


export default function Ships(props) {
    const { dic, bounds, zoom, imagesUrl, setIsLogged } = props;
    const { enqueueSnackbar } = useSnackbar()
    const [ships, setShips] = useState([])
    const [selectedShip, setSelectedShip] = useState(false)
    const [timerOn, setTimerOn] = useState(false)
    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const markerRefs = useRef({});
    const [done, setDone] = useState(false);

    const map = useMap();
    
    useEffect(() => {
        const getShips = () => {
            //setShips([])
            //console.log("bounds: ");
            
            const actualBounds = bounds ? bounds : {
                n: map.getBounds().getNorth(),
                e: map.getBounds().getEast(),
                w: map.getBounds().getWest(),
                s: map.getBounds().getSouth()
            };
            
            aisServices.getShips(actualBounds)
                .then(resp => {
                    let { result } = resp.header;
                    setShips(result === 'ok' ? resp.response.details : [])

                    if (result !== 'ok'){
                        //enqueueSnackbar(resp.response.details[0], { variant: 'error', persist: false })
                        setIsLogged(false);
                    }
            })
        }

        const getShipsWithTimer = () => {
            getShips();
            setTimeout(getShipsWithTimer, 30000)
        }

        // se il timer non è attivato
        if (!timerOn){
            setTimerOn(true)
            getShipsWithTimer();
        }
        else{
            getShips();
        }
        
    }, [bounds, timerOn, setTimerOn, map, enqueueSnackbar])

    useEffect(() => {
        
        if (selectedShip){
            // map.flyTo(selectedShip.position)
            if (markerRefs.current[selectedShip.mmsi])
                markerRefs.current[selectedShip.mmsi].openPopup()
            else
                console.log(markerRefs.current[selectedShip.mmsi], selectedShip.mmsi, selectedShip)
        }
    }, [selectedShip, map, ships])

    return (
        <Fragment>
            <Control prepend position='topright' style={{backgroundColor: "white"}}>
                <SearchDialog
                    dic={dic}
                    ships={ships}
                    setSelectedShip={setSelectedShip}
                    markerRefs={markerRefs}
                    open={searchDialogOpen}
                    setOpen={setSearchDialogOpen}
                />
            </Control>
                {ships.map((ship, index) => 
                    <Ship
                        dic={dic}
                        ship={ship}
                        zoom={zoom}
                        setSelectedShip={setSelectedShip}
                        markerRefs={markerRefs}
                        done={done}
                        setDone={setDone}
                        ships={ships}
                        index={index}
                        key={ship.mmsi}
                        imagesUrl={imagesUrl}
                        
                    />
                )}
        </Fragment>
    );
}