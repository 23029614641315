import React, { useState, forwardRef, Fragment, useEffect } from 'react';
import { Dialog, Toolbar, AppBar, IconButton, Typography, Grid, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import Slide from '@mui/material/Slide';
import Flag from 'react-world-flags'

import { shipsServices } from '../_services';

import ShipData from './ShipInfo/ShipData';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShipInfo(props) {
    const { ship, dic } = props;
    const [open, setOpen] = useState(false);
    const [shipData, setShipData] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(open){
            shipsServices.getShipDetails(ship.mmsi)
            .then(resp => {
                let { header, response } = resp;
                let { details } = response
                if (header.result === 'ok'){
                    setShipData(details)
                }
            })
        }

    }, [ship, open])

    return (
        <Fragment>
            <IconButton onClick={handleClickOpen} size="small">
                <UnfoldMoreIcon fontSize="inherit" />
            </IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                style={{ zIndex: 7}}
            >
                <AppBar>
                    <Toolbar>
                        <Tooltip>
                            <Flag code={ship.flag.iso_code} height="16"></Flag>
                        </Tooltip>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {ship.name}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            >
                                <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container style={{marginTop: 60}} spacing={1}>
                    {shipData &&
                        <Grid item xs={12} md={12} lg={12}>
                            <ShipData
                                dic={dic}
                                ship={{...shipData, flag: {...ship.flag}}}
                                title={dic.DATA+" "+dic.SHIP}
                                isAis={false}
                            />
                        </Grid>
                    }
                </Grid>
            </Dialog>
        </Fragment>
    );
}