import React, { Fragment } from 'react';
import { Polygon, Circle, Tooltip } from 'react-leaflet'
import { BrowserView } from 'react-device-detect';

import "leaflet/dist/leaflet.css";

function pointsToPolygon(points){
    return points.map((point) => [point.lt, point.lg])
}

export default function Roadsteads(props) {
  const { roadsteads } = props
  const options = { color: '#266b99' , weight: 0.5}
  
  return (
    <Fragment>
        {Object.keys(roadsteads).map((key) => {
            if (roadsteads[key].points.length > 1)
              return(
                <Polygon
                    key={key}
                    pathOptions={roadsteads[key].color ? {color: roadsteads[key].color, weight: 0.7, ...roadsteads[key].fillColor && {fillColor: roadsteads[key].fillColor, dashArray: '5, 10', weight: 3}} : options}
                    positions={pointsToPolygon(roadsteads[key].points)}
                >
                  <BrowserView><Tooltip sticky>{roadsteads[key].name}</Tooltip></BrowserView>
                </Polygon>
              )
            else{
              return(
                <Circle
                    key={key}
                    center={[roadsteads[key].points[0].lt, roadsteads[key].points[0].lg]}
                    pathOptions={options}
                    radius={roadsteads[key].radius ? roadsteads[key].radius : 100}
                >
                  <BrowserView><Tooltip>{roadsteads[key].name}</Tooltip></BrowserView>
                </Circle>
              )
            }
          }
        )}
    </Fragment>
  );
}