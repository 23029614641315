import { setCall, handleResponse } from '../_helpers';

export const aisServices = {
    getShips, getConfiguration
}

async function getShips(bounds) {
    const requestOptions = {
      method: 'POST',
      body: setCall(
                'ships',
                'get_ships_in_polygon',
                {
                  polygon: [
                    {lt: bounds.n, lg: bounds.e},
                    {lt: bounds.n, lg: bounds.w},
                    {lt: bounds.s, lg: bounds.w},
                    {lt: bounds.s, lg: bounds.e}
                  ]
                }),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}

async function getConfiguration() {
    const requestOptions = {
      method: 'POST',
      body: setCall('users', 'get_configuration', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}