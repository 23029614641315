import { setCall, handleResponse } from '../_helpers';

export const shipsServices = {
    getShipDetails
}

async function getShipDetails(mmsi) {
    const requestOptions = {
      method: 'POST',
      body: setCall('ships', 'ship_informations_by_mmsi', {mmsi: mmsi}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)
  
    return json;
}