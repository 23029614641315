import React, { useState, useEffect }  from 'react';
import './App.css';

import { aisServices } from './_services'
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Login from './_components/Login'
import Map from './_components/Map'
import { dic } from './_constants/dic_it'

import { usersServices } from './_services/users.services'

const App = () => {
  const [roadsteads, setRoadsteads] = useState(false)
  const [position, setPosition] = useState(false);
  const [imagesUrl, setImagesUrl] = useState(false)
  const [keys, setKeys] = useState(false);
  const [userIsLogged, setUserIsLogged] = useState(null);
  
  useEffect(() => {
    console.log(userIsLogged)
    switch(userIsLogged){
      case null: localStorage.getItem('isLogged') && setUserIsLogged(true); break;
      case false: localStorage.removeItem('isLogged'); break;
      default: console.log(true, 'default')
    }

    // se la licenza è stata registrata
    if (localStorage.getItem('keys')){
      setKeys(JSON.parse(localStorage.getItem('keys')))
      
      userIsLogged &&
          aisServices.getConfiguration()
              .then(resp => {
                  let { result } = resp.header;
                  if (result === 'ok'){
                    const {actor_info} = resp.response.details
                    // Fetch the current position using browser geolocation API
                    navigator.geolocation.getCurrentPosition(
                      (pos) => {
                        const { latitude, longitude } = pos.coords;
                        setPosition([latitude, longitude]);
                        //console.log([latitude, longitude])
                      },
                      (error) => {
                        setPosition([Number(actor_info.lt), Number(actor_info.lg)])
                      }
                    );
                    //
                    setRoadsteads(result === 'ok' ? resp.response.details.roadsteads : false)
                    setImagesUrl('')
                  }
                  else{
                    localStorage.removeItem('isLogged')
                    window.location.reload()
                  }
                  //setRoadsteads(result === 'ok' ? resp.response.details.roadsteads : false)
                  //setImagesUrl(result === 'ok' ? resp.response.details.images_url : '')
          })

      if (localStorage.getItem('isLogged') && !userIsLogged)
        setUserIsLogged(true)
      
      
      const sessionChecker = () => {
        if (userIsLogged)
          usersServices.isLogged().then(resp => {
            let { result } = resp.header;
            result === 'denied' && setUserIsLogged(false)
          })
      }
      window.addEventListener("focus", sessionChecker)
      return () => { window.removeEventListener("focus", sessionChecker) }
    }
  }, [userIsLogged])
  

  return (
    <SnackbarProvider maxSnack={3}>
        <ThemeProvider 
          theme={createTheme({
            palette: {
              primary: {
                light: '#8197b9',
                main: '#627ea8',
                dark: '#445875',
              }
            },
          })
        }>
        {userIsLogged && position ? 
          <Map 
            dic={dic}
            roadsteads={roadsteads}
            position={position}
            imagesUrl={imagesUrl}
            setIsLogged={setUserIsLogged}
          />
          :
          <div style={{
                  backgroundImage: "url(images/bg.jpg)",
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  minHeight: "100%",
                  height: "100vh",
                  position: "relative",
              }}>
              <div style={{paddingTop: 30}}>
                <Login
                  keys={keys}
                  setKeys={setKeys}
                  isLogged={userIsLogged}
                  setIsLogged={setUserIsLogged}
                />
              </div>
          </div>
        }
        </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
