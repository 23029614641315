import React, { Fragment } from 'react';
import { IconButton, List, ListItem,
         TextField, Autocomplete, Drawer, ListItemButton, ListItemText
        } from '@mui/material';


import { MdSearch } from "react-icons/md";
import { shipData } from '../_helpers/utils'
import { useMap } from 'react-leaflet';


export default function SearchDialog(props) {
    const { dic, ships, setSelectedShip, open, setOpen } = props    

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const map = useMap();

    return (
        <Fragment>
            <IconButton aria-label={dic.SEARCH} onClick={handleClickOpen} style={{padding: 10}} >
                <MdSearch />
            </IconButton>
            <Drawer
                open={open}
                onClose={handleClose}
                anchor="right"
                PaperProps={{
                    sx: {
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      padding:2
                    }
                  }}
            >
                <Autocomplete
                    disablePortal
                    options={ships.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))}
                    onChange={(event, selectedShip) => {
                            map.closePopup()
                            const ship = shipData(selectedShip);
                            setSelectedShip({mmsi: ship.mmsi, position: ship.position})
                            setOpen(false)
                    }}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={dic.SHIPS} />}
                />
                <List dense={true}>
                    {ships.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((ship, index) => 
                        <ListItem disablePadding key={ship.mmsi} onClick={() => {
                            map.closePopup()
                            const sD = shipData(ship);
                            setSelectedShip({mmsi: sD.mmsi, position: sD.position})
                            setOpen(false)
                        }}>
                            <ListItemButton>
                                <ListItemText primary={ship.name !== "" ? ship.name : "mmsi: "+ship.mmsi} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Drawer>
        </Fragment>
    );
}
