import React from 'react';
import { IconButton } from '@mui/material';


import { usersServices } from '../_services'
import { MdLogout } from "react-icons/md";


export default function Login(props) {
    const { setIsLogged, dic } = props

    const logout = (e) => {
        e.preventDefault();
        const date = new Date();
        usersServices.logout().then( resp => {
            //localStorage.removeItem('isLogged')
            setIsLogged(false)
        })
    }

    return (
        <IconButton aria-label={dic.LOGOUT} onClick={logout} style={{padding: 10}} >
            <MdLogout />
        </IconButton>
    );
}
