export const arrows = []
arrows['arrow_0.png'] = []
arrows['arrow_0.png']['width'] = '12';
arrows['arrow_0.png']['height'] = '20';
arrows['arrow_1.png'] = []
arrows['arrow_1.png']['width'] = '12';
arrows['arrow_1.png']['height'] = '20';
arrows['arrow_2.png'] = []
arrows['arrow_2.png']['width'] = '13';
arrows['arrow_2.png']['height'] = '20';
arrows['arrow_3.png'] = []
arrows['arrow_3.png']['width'] = '13';
arrows['arrow_3.png']['height'] = '21';
arrows['arrow_4.png'] = []
arrows['arrow_4.png']['width'] = '13';
arrows['arrow_4.png']['height'] = '21';
arrows['arrow_5.png'] = []
arrows['arrow_5.png']['width'] = '14';
arrows['arrow_5.png']['height'] = '21';
arrows['arrow_6.png'] = []
arrows['arrow_6.png']['width'] = '14';
arrows['arrow_6.png']['height'] = '21';
arrows['arrow_7.png'] = []
arrows['arrow_7.png']['width'] = '14';
arrows['arrow_7.png']['height'] = '21';
arrows['arrow_8.png'] = []
arrows['arrow_8.png']['width'] = '15';
arrows['arrow_8.png']['height'] = '21';
arrows['arrow_9.png'] = []
arrows['arrow_9.png']['width'] = '15';
arrows['arrow_9.png']['height'] = '22';
arrows['arrow_10.png'] = []
arrows['arrow_10.png']['width'] = '15';
arrows['arrow_10.png']['height'] = '22';
arrows['arrow_11.png'] = []
arrows['arrow_11.png']['width'] = '16';
arrows['arrow_11.png']['height'] = '22';
arrows['arrow_12.png'] = []
arrows['arrow_12.png']['width'] = '16';
arrows['arrow_12.png']['height'] = '22';
arrows['arrow_13.png'] = []
arrows['arrow_13.png']['width'] = '16';
arrows['arrow_13.png']['height'] = '22';
arrows['arrow_14.png'] = []
arrows['arrow_14.png']['width'] = '16';
arrows['arrow_14.png']['height'] = '22';
arrows['arrow_15.png'] = []
arrows['arrow_15.png']['width'] = '17';
arrows['arrow_15.png']['height'] = '22';
arrows['arrow_16.png'] = []
arrows['arrow_16.png']['width'] = '17';
arrows['arrow_16.png']['height'] = '23';
arrows['arrow_17.png'] = []
arrows['arrow_17.png']['width'] = '17';
arrows['arrow_17.png']['height'] = '23';
arrows['arrow_18.png'] = []
arrows['arrow_18.png']['width'] = '18';
arrows['arrow_18.png']['height'] = '23';
arrows['arrow_19.png'] = []
arrows['arrow_19.png']['width'] = '18';
arrows['arrow_19.png']['height'] = '23';
arrows['arrow_20.png'] = []
arrows['arrow_20.png']['width'] = '18';
arrows['arrow_20.png']['height'] = '23';
arrows['arrow_21.png'] = []
arrows['arrow_21.png']['width'] = '18';
arrows['arrow_21.png']['height'] = '23';
arrows['arrow_22.png'] = []
arrows['arrow_22.png']['width'] = '19';
arrows['arrow_22.png']['height'] = '23';
arrows['arrow_23.png'] = []
arrows['arrow_23.png']['width'] = '19';
arrows['arrow_23.png']['height'] = '23';
arrows['arrow_24.png'] = []
arrows['arrow_24.png']['width'] = '19';
arrows['arrow_24.png']['height'] = '23';
arrows['arrow_25.png'] = []
arrows['arrow_25.png']['width'] = '19';
arrows['arrow_25.png']['height'] = '23';
arrows['arrow_26.png'] = []
arrows['arrow_26.png']['width'] = '20';
arrows['arrow_26.png']['height'] = '23';
arrows['arrow_27.png'] = []
arrows['arrow_27.png']['width'] = '20';
arrows['arrow_27.png']['height'] = '23';
arrows['arrow_28.png'] = []
arrows['arrow_28.png']['width'] = '20';
arrows['arrow_28.png']['height'] = '23';
arrows['arrow_29.png'] = []
arrows['arrow_29.png']['width'] = '20';
arrows['arrow_29.png']['height'] = '23';
arrows['arrow_30.png'] = []
arrows['arrow_30.png']['width'] = '20';
arrows['arrow_30.png']['height'] = '23';
arrows['arrow_31.png'] = []
arrows['arrow_31.png']['width'] = '21';
arrows['arrow_31.png']['height'] = '23';
arrows['arrow_32.png'] = []
arrows['arrow_32.png']['width'] = '21';
arrows['arrow_32.png']['height'] = '23';
arrows['arrow_33.png'] = []
arrows['arrow_33.png']['width'] = '21';
arrows['arrow_33.png']['height'] = '23';
arrows['arrow_34.png'] = []
arrows['arrow_34.png']['width'] = '21';
arrows['arrow_34.png']['height'] = '23';
arrows['arrow_35.png'] = []
arrows['arrow_35.png']['width'] = '21';
arrows['arrow_35.png']['height'] = '23';
arrows['arrow_36.png'] = []
arrows['arrow_36.png']['width'] = '21';
arrows['arrow_36.png']['height'] = '23';
arrows['arrow_37.png'] = []
arrows['arrow_37.png']['width'] = '22';
arrows['arrow_37.png']['height'] = '23';
arrows['arrow_38.png'] = []
arrows['arrow_38.png']['width'] = '22';
arrows['arrow_38.png']['height'] = '23';
arrows['arrow_39.png'] = []
arrows['arrow_39.png']['width'] = '22';
arrows['arrow_39.png']['height'] = '23';
arrows['arrow_40.png'] = []
arrows['arrow_40.png']['width'] = '22';
arrows['arrow_40.png']['height'] = '23';
arrows['arrow_41.png'] = []
arrows['arrow_41.png']['width'] = '22';
arrows['arrow_41.png']['height'] = '23';
arrows['arrow_42.png'] = []
arrows['arrow_42.png']['width'] = '22';
arrows['arrow_42.png']['height'] = '23';
arrows['arrow_43.png'] = []
arrows['arrow_43.png']['width'] = '22';
arrows['arrow_43.png']['height'] = '23';
arrows['arrow_44.png'] = []
arrows['arrow_44.png']['width'] = '23';
arrows['arrow_44.png']['height'] = '23';
arrows['arrow_45.png'] = []
arrows['arrow_45.png']['width'] = '23';
arrows['arrow_45.png']['height'] = '23';
arrows['arrow_46.png'] = []
arrows['arrow_46.png']['width'] = '23';
arrows['arrow_46.png']['height'] = '23';
arrows['arrow_47.png'] = []
arrows['arrow_47.png']['width'] = '23';
arrows['arrow_47.png']['height'] = '22';
arrows['arrow_48.png'] = []
arrows['arrow_48.png']['width'] = '23';
arrows['arrow_48.png']['height'] = '22';
arrows['arrow_49.png'] = []
arrows['arrow_49.png']['width'] = '23';
arrows['arrow_49.png']['height'] = '22';
arrows['arrow_50.png'] = []
arrows['arrow_50.png']['width'] = '23';
arrows['arrow_50.png']['height'] = '22';
arrows['arrow_51.png'] = []
arrows['arrow_51.png']['width'] = '23';
arrows['arrow_51.png']['height'] = '22';
arrows['arrow_52.png'] = []
arrows['arrow_52.png']['width'] = '23';
arrows['arrow_52.png']['height'] = '22';
arrows['arrow_53.png'] = []
arrows['arrow_53.png']['width'] = '23';
arrows['arrow_53.png']['height'] = '22';
arrows['arrow_54.png'] = []
arrows['arrow_54.png']['width'] = '23';
arrows['arrow_54.png']['height'] = '21';
arrows['arrow_55.png'] = []
arrows['arrow_55.png']['width'] = '23';
arrows['arrow_55.png']['height'] = '21';
arrows['arrow_56.png'] = []
arrows['arrow_56.png']['width'] = '23';
arrows['arrow_56.png']['height'] = '21';
arrows['arrow_57.png'] = []
arrows['arrow_57.png']['width'] = '23';
arrows['arrow_57.png']['height'] = '21';
arrows['arrow_58.png'] = []
arrows['arrow_58.png']['width'] = '23';
arrows['arrow_58.png']['height'] = '21';
arrows['arrow_59.png'] = []
arrows['arrow_59.png']['width'] = '23';
arrows['arrow_59.png']['height'] = '21';
arrows['arrow_60.png'] = []
arrows['arrow_60.png']['width'] = '23';
arrows['arrow_60.png']['height'] = '20';
arrows['arrow_61.png'] = []
arrows['arrow_61.png']['width'] = '23';
arrows['arrow_61.png']['height'] = '20';
arrows['arrow_62.png'] = []
arrows['arrow_62.png']['width'] = '23';
arrows['arrow_62.png']['height'] = '20';
arrows['arrow_63.png'] = []
arrows['arrow_63.png']['width'] = '23';
arrows['arrow_63.png']['height'] = '20';
arrows['arrow_64.png'] = []
arrows['arrow_64.png']['width'] = '23';
arrows['arrow_64.png']['height'] = '20';
arrows['arrow_65.png'] = []
arrows['arrow_65.png']['width'] = '23';
arrows['arrow_65.png']['height'] = '19';
arrows['arrow_66.png'] = []
arrows['arrow_66.png']['width'] = '23';
arrows['arrow_66.png']['height'] = '19';
arrows['arrow_67.png'] = []
arrows['arrow_67.png']['width'] = '23';
arrows['arrow_67.png']['height'] = '19';
arrows['arrow_68.png'] = []
arrows['arrow_68.png']['width'] = '23';
arrows['arrow_68.png']['height'] = '19';
arrows['arrow_69.png'] = []
arrows['arrow_69.png']['width'] = '23';
arrows['arrow_69.png']['height'] = '18';
arrows['arrow_70.png'] = []
arrows['arrow_70.png']['width'] = '23';
arrows['arrow_70.png']['height'] = '18';
arrows['arrow_71.png'] = []
arrows['arrow_71.png']['width'] = '23';
arrows['arrow_71.png']['height'] = '18';
arrows['arrow_72.png'] = []
arrows['arrow_72.png']['width'] = '23';
arrows['arrow_72.png']['height'] = '18';
arrows['arrow_73.png'] = []
arrows['arrow_73.png']['width'] = '23';
arrows['arrow_73.png']['height'] = '17';
arrows['arrow_74.png'] = []
arrows['arrow_74.png']['width'] = '23';
arrows['arrow_74.png']['height'] = '17';
arrows['arrow_75.png'] = []
arrows['arrow_75.png']['width'] = '22';
arrows['arrow_75.png']['height'] = '17';
arrows['arrow_76.png'] = []
arrows['arrow_76.png']['width'] = '22';
arrows['arrow_76.png']['height'] = '16';
arrows['arrow_77.png'] = []
arrows['arrow_77.png']['width'] = '22';
arrows['arrow_77.png']['height'] = '16';
arrows['arrow_78.png'] = []
arrows['arrow_78.png']['width'] = '22';
arrows['arrow_78.png']['height'] = '16';
arrows['arrow_79.png'] = []
arrows['arrow_79.png']['width'] = '22';
arrows['arrow_79.png']['height'] = '16';
arrows['arrow_80.png'] = []
arrows['arrow_80.png']['width'] = '22';
arrows['arrow_80.png']['height'] = '15';
arrows['arrow_81.png'] = []
arrows['arrow_81.png']['width'] = '22';
arrows['arrow_81.png']['height'] = '15';
arrows['arrow_82.png'] = []
arrows['arrow_82.png']['width'] = '21';
arrows['arrow_82.png']['height'] = '15';
arrows['arrow_83.png'] = []
arrows['arrow_83.png']['width'] = '21';
arrows['arrow_83.png']['height'] = '14';
arrows['arrow_84.png'] = []
arrows['arrow_84.png']['width'] = '21';
arrows['arrow_84.png']['height'] = '14';
arrows['arrow_85.png'] = []
arrows['arrow_85.png']['width'] = '21';
arrows['arrow_85.png']['height'] = '14';
arrows['arrow_86.png'] = []
arrows['arrow_86.png']['width'] = '21';
arrows['arrow_86.png']['height'] = '13';
arrows['arrow_87.png'] = []
arrows['arrow_87.png']['width'] = '21';
arrows['arrow_87.png']['height'] = '13';
arrows['arrow_88.png'] = []
arrows['arrow_88.png']['width'] = '20';
arrows['arrow_88.png']['height'] = '13';
arrows['arrow_89.png'] = []
arrows['arrow_89.png']['width'] = '20';
arrows['arrow_89.png']['height'] = '12';
arrows['arrow_90.png'] = []
arrows['arrow_90.png']['width'] = '20';
arrows['arrow_90.png']['height'] = '12';
arrows['arrow_91.png'] = []
arrows['arrow_91.png']['width'] = '20';
arrows['arrow_91.png']['height'] = '12';
arrows['arrow_92.png'] = []
arrows['arrow_92.png']['width'] = '20';
arrows['arrow_92.png']['height'] = '13';
arrows['arrow_93.png'] = []
arrows['arrow_93.png']['width'] = '21';
arrows['arrow_93.png']['height'] = '13';
arrows['arrow_94.png'] = []
arrows['arrow_94.png']['width'] = '21';
arrows['arrow_94.png']['height'] = '13';
arrows['arrow_95.png'] = []
arrows['arrow_95.png']['width'] = '21';
arrows['arrow_95.png']['height'] = '14';
arrows['arrow_96.png'] = []
arrows['arrow_96.png']['width'] = '21';
arrows['arrow_96.png']['height'] = '14';
arrows['arrow_97.png'] = []
arrows['arrow_97.png']['width'] = '21';
arrows['arrow_97.png']['height'] = '14';
arrows['arrow_98.png'] = []
arrows['arrow_98.png']['width'] = '21';
arrows['arrow_98.png']['height'] = '15';
arrows['arrow_99.png'] = []
arrows['arrow_99.png']['width'] = '22';
arrows['arrow_99.png']['height'] = '15';
arrows['arrow_100.png'] = []
arrows['arrow_100.png']['width'] = '22';
arrows['arrow_100.png']['height'] = '15';
arrows['arrow_101.png'] = []
arrows['arrow_101.png']['width'] = '22';
arrows['arrow_101.png']['height'] = '16';
arrows['arrow_102.png'] = []
arrows['arrow_102.png']['width'] = '22';
arrows['arrow_102.png']['height'] = '16';
arrows['arrow_103.png'] = []
arrows['arrow_103.png']['width'] = '22';
arrows['arrow_103.png']['height'] = '16';
arrows['arrow_104.png'] = []
arrows['arrow_104.png']['width'] = '22';
arrows['arrow_104.png']['height'] = '16';
arrows['arrow_105.png'] = []
arrows['arrow_105.png']['width'] = '22';
arrows['arrow_105.png']['height'] = '17';
arrows['arrow_106.png'] = []
arrows['arrow_106.png']['width'] = '23';
arrows['arrow_106.png']['height'] = '17';
arrows['arrow_107.png'] = []
arrows['arrow_107.png']['width'] = '23';
arrows['arrow_107.png']['height'] = '17';
arrows['arrow_108.png'] = []
arrows['arrow_108.png']['width'] = '23';
arrows['arrow_108.png']['height'] = '18';
arrows['arrow_109.png'] = []
arrows['arrow_109.png']['width'] = '23';
arrows['arrow_109.png']['height'] = '18';
arrows['arrow_110.png'] = []
arrows['arrow_110.png']['width'] = '23';
arrows['arrow_110.png']['height'] = '18';
arrows['arrow_111.png'] = []
arrows['arrow_111.png']['width'] = '23';
arrows['arrow_111.png']['height'] = '18';
arrows['arrow_112.png'] = []
arrows['arrow_112.png']['width'] = '23';
arrows['arrow_112.png']['height'] = '19';
arrows['arrow_113.png'] = []
arrows['arrow_113.png']['width'] = '23';
arrows['arrow_113.png']['height'] = '19';
arrows['arrow_114.png'] = []
arrows['arrow_114.png']['width'] = '23';
arrows['arrow_114.png']['height'] = '19';
arrows['arrow_115.png'] = []
arrows['arrow_115.png']['width'] = '23';
arrows['arrow_115.png']['height'] = '19';
arrows['arrow_116.png'] = []
arrows['arrow_116.png']['width'] = '23';
arrows['arrow_116.png']['height'] = '20';
arrows['arrow_117.png'] = []
arrows['arrow_117.png']['width'] = '23';
arrows['arrow_117.png']['height'] = '20';
arrows['arrow_118.png'] = []
arrows['arrow_118.png']['width'] = '23';
arrows['arrow_118.png']['height'] = '20';
arrows['arrow_119.png'] = []
arrows['arrow_119.png']['width'] = '23';
arrows['arrow_119.png']['height'] = '20';
arrows['arrow_120.png'] = []
arrows['arrow_120.png']['width'] = '23';
arrows['arrow_120.png']['height'] = '20';
arrows['arrow_121.png'] = []
arrows['arrow_121.png']['width'] = '23';
arrows['arrow_121.png']['height'] = '21';
arrows['arrow_122.png'] = []
arrows['arrow_122.png']['width'] = '23';
arrows['arrow_122.png']['height'] = '21';
arrows['arrow_123.png'] = []
arrows['arrow_123.png']['width'] = '23';
arrows['arrow_123.png']['height'] = '21';
arrows['arrow_124.png'] = []
arrows['arrow_124.png']['width'] = '23';
arrows['arrow_124.png']['height'] = '21';
arrows['arrow_125.png'] = []
arrows['arrow_125.png']['width'] = '23';
arrows['arrow_125.png']['height'] = '21';
arrows['arrow_126.png'] = []
arrows['arrow_126.png']['width'] = '23';
arrows['arrow_126.png']['height'] = '21';
arrows['arrow_127.png'] = []
arrows['arrow_127.png']['width'] = '23';
arrows['arrow_127.png']['height'] = '22';
arrows['arrow_128.png'] = []
arrows['arrow_128.png']['width'] = '23';
arrows['arrow_128.png']['height'] = '22';
arrows['arrow_129.png'] = []
arrows['arrow_129.png']['width'] = '23';
arrows['arrow_129.png']['height'] = '22';
arrows['arrow_130.png'] = []
arrows['arrow_130.png']['width'] = '23';
arrows['arrow_130.png']['height'] = '22';
arrows['arrow_131.png'] = []
arrows['arrow_131.png']['width'] = '23';
arrows['arrow_131.png']['height'] = '22';
arrows['arrow_132.png'] = []
arrows['arrow_132.png']['width'] = '23';
arrows['arrow_132.png']['height'] = '22';
arrows['arrow_133.png'] = []
arrows['arrow_133.png']['width'] = '23';
arrows['arrow_133.png']['height'] = '22';
arrows['arrow_134.png'] = []
arrows['arrow_134.png']['width'] = '23';
arrows['arrow_134.png']['height'] = '23';
arrows['arrow_135.png'] = []
arrows['arrow_135.png']['width'] = '23';
arrows['arrow_135.png']['height'] = '23';
arrows['arrow_136.png'] = []
arrows['arrow_136.png']['width'] = '23';
arrows['arrow_136.png']['height'] = '23';
arrows['arrow_137.png'] = []
arrows['arrow_137.png']['width'] = '22';
arrows['arrow_137.png']['height'] = '23';
arrows['arrow_138.png'] = []
arrows['arrow_138.png']['width'] = '22';
arrows['arrow_138.png']['height'] = '23';
arrows['arrow_139.png'] = []
arrows['arrow_139.png']['width'] = '22';
arrows['arrow_139.png']['height'] = '23';
arrows['arrow_140.png'] = []
arrows['arrow_140.png']['width'] = '22';
arrows['arrow_140.png']['height'] = '23';
arrows['arrow_141.png'] = []
arrows['arrow_141.png']['width'] = '22';
arrows['arrow_141.png']['height'] = '23';
arrows['arrow_142.png'] = []
arrows['arrow_142.png']['width'] = '22';
arrows['arrow_142.png']['height'] = '23';
arrows['arrow_143.png'] = []
arrows['arrow_143.png']['width'] = '22';
arrows['arrow_143.png']['height'] = '23';
arrows['arrow_144.png'] = []
arrows['arrow_144.png']['width'] = '21';
arrows['arrow_144.png']['height'] = '23';
arrows['arrow_145.png'] = []
arrows['arrow_145.png']['width'] = '21';
arrows['arrow_145.png']['height'] = '23';
arrows['arrow_146.png'] = []
arrows['arrow_146.png']['width'] = '21';
arrows['arrow_146.png']['height'] = '23';
arrows['arrow_147.png'] = []
arrows['arrow_147.png']['width'] = '21';
arrows['arrow_147.png']['height'] = '23';
arrows['arrow_148.png'] = []
arrows['arrow_148.png']['width'] = '21';
arrows['arrow_148.png']['height'] = '23';
arrows['arrow_149.png'] = []
arrows['arrow_149.png']['width'] = '21';
arrows['arrow_149.png']['height'] = '23';
arrows['arrow_150.png'] = []
arrows['arrow_150.png']['width'] = '20';
arrows['arrow_150.png']['height'] = '23';
arrows['arrow_151.png'] = []
arrows['arrow_151.png']['width'] = '20';
arrows['arrow_151.png']['height'] = '23';
arrows['arrow_152.png'] = []
arrows['arrow_152.png']['width'] = '20';
arrows['arrow_152.png']['height'] = '23';
arrows['arrow_153.png'] = []
arrows['arrow_153.png']['width'] = '20';
arrows['arrow_153.png']['height'] = '23';
arrows['arrow_154.png'] = []
arrows['arrow_154.png']['width'] = '20';
arrows['arrow_154.png']['height'] = '23';
arrows['arrow_155.png'] = []
arrows['arrow_155.png']['width'] = '19';
arrows['arrow_155.png']['height'] = '23';
arrows['arrow_156.png'] = []
arrows['arrow_156.png']['width'] = '19';
arrows['arrow_156.png']['height'] = '23';
arrows['arrow_157.png'] = []
arrows['arrow_157.png']['width'] = '19';
arrows['arrow_157.png']['height'] = '23';
arrows['arrow_158.png'] = []
arrows['arrow_158.png']['width'] = '19';
arrows['arrow_158.png']['height'] = '23';
arrows['arrow_159.png'] = []
arrows['arrow_159.png']['width'] = '18';
arrows['arrow_159.png']['height'] = '23';
arrows['arrow_160.png'] = []
arrows['arrow_160.png']['width'] = '18';
arrows['arrow_160.png']['height'] = '23';
arrows['arrow_161.png'] = []
arrows['arrow_161.png']['width'] = '18';
arrows['arrow_161.png']['height'] = '23';
arrows['arrow_162.png'] = []
arrows['arrow_162.png']['width'] = '18';
arrows['arrow_162.png']['height'] = '23';
arrows['arrow_163.png'] = []
arrows['arrow_163.png']['width'] = '17';
arrows['arrow_163.png']['height'] = '23';
arrows['arrow_164.png'] = []
arrows['arrow_164.png']['width'] = '17';
arrows['arrow_164.png']['height'] = '23';
arrows['arrow_165.png'] = []
arrows['arrow_165.png']['width'] = '17';
arrows['arrow_165.png']['height'] = '22';
arrows['arrow_166.png'] = []
arrows['arrow_166.png']['width'] = '16';
arrows['arrow_166.png']['height'] = '22';
arrows['arrow_167.png'] = []
arrows['arrow_167.png']['width'] = '16';
arrows['arrow_167.png']['height'] = '22';
arrows['arrow_168.png'] = []
arrows['arrow_168.png']['width'] = '16';
arrows['arrow_168.png']['height'] = '22';
arrows['arrow_169.png'] = []
arrows['arrow_169.png']['width'] = '16';
arrows['arrow_169.png']['height'] = '22';
arrows['arrow_170.png'] = []
arrows['arrow_170.png']['width'] = '15';
arrows['arrow_170.png']['height'] = '22';
arrows['arrow_171.png'] = []
arrows['arrow_171.png']['width'] = '15';
arrows['arrow_171.png']['height'] = '22';
arrows['arrow_172.png'] = []
arrows['arrow_172.png']['width'] = '15';
arrows['arrow_172.png']['height'] = '21';
arrows['arrow_173.png'] = []
arrows['arrow_173.png']['width'] = '14';
arrows['arrow_173.png']['height'] = '21';
arrows['arrow_174.png'] = []
arrows['arrow_174.png']['width'] = '14';
arrows['arrow_174.png']['height'] = '21';
arrows['arrow_175.png'] = []
arrows['arrow_175.png']['width'] = '14';
arrows['arrow_175.png']['height'] = '21';
arrows['arrow_176.png'] = []
arrows['arrow_176.png']['width'] = '13';
arrows['arrow_176.png']['height'] = '21';
arrows['arrow_177.png'] = []
arrows['arrow_177.png']['width'] = '13';
arrows['arrow_177.png']['height'] = '21';
arrows['arrow_178.png'] = []
arrows['arrow_178.png']['width'] = '13';
arrows['arrow_178.png']['height'] = '20';
arrows['arrow_179.png'] = []
arrows['arrow_179.png']['width'] = '12';
arrows['arrow_179.png']['height'] = '20';
arrows['arrow_180.png'] = []
arrows['arrow_180.png']['width'] = '12';
arrows['arrow_180.png']['height'] = '20';
arrows['arrow_181.png'] = []
arrows['arrow_181.png']['width'] = '12';
arrows['arrow_181.png']['height'] = '20';
arrows['arrow_182.png'] = []
arrows['arrow_182.png']['width'] = '13';
arrows['arrow_182.png']['height'] = '20';
arrows['arrow_183.png'] = []
arrows['arrow_183.png']['width'] = '13';
arrows['arrow_183.png']['height'] = '21';
arrows['arrow_184.png'] = []
arrows['arrow_184.png']['width'] = '13';
arrows['arrow_184.png']['height'] = '21';
arrows['arrow_185.png'] = []
arrows['arrow_185.png']['width'] = '14';
arrows['arrow_185.png']['height'] = '21';
arrows['arrow_186.png'] = []
arrows['arrow_186.png']['width'] = '14';
arrows['arrow_186.png']['height'] = '21';
arrows['arrow_187.png'] = []
arrows['arrow_187.png']['width'] = '14';
arrows['arrow_187.png']['height'] = '21';
arrows['arrow_188.png'] = []
arrows['arrow_188.png']['width'] = '15';
arrows['arrow_188.png']['height'] = '21';
arrows['arrow_189.png'] = []
arrows['arrow_189.png']['width'] = '15';
arrows['arrow_189.png']['height'] = '22';
arrows['arrow_190.png'] = []
arrows['arrow_190.png']['width'] = '15';
arrows['arrow_190.png']['height'] = '22';
arrows['arrow_191.png'] = []
arrows['arrow_191.png']['width'] = '16';
arrows['arrow_191.png']['height'] = '22';
arrows['arrow_192.png'] = []
arrows['arrow_192.png']['width'] = '16';
arrows['arrow_192.png']['height'] = '22';
arrows['arrow_193.png'] = []
arrows['arrow_193.png']['width'] = '16';
arrows['arrow_193.png']['height'] = '22';
arrows['arrow_194.png'] = []
arrows['arrow_194.png']['width'] = '16';
arrows['arrow_194.png']['height'] = '22';
arrows['arrow_195.png'] = []
arrows['arrow_195.png']['width'] = '17';
arrows['arrow_195.png']['height'] = '22';
arrows['arrow_196.png'] = []
arrows['arrow_196.png']['width'] = '17';
arrows['arrow_196.png']['height'] = '23';
arrows['arrow_197.png'] = []
arrows['arrow_197.png']['width'] = '17';
arrows['arrow_197.png']['height'] = '23';
arrows['arrow_198.png'] = []
arrows['arrow_198.png']['width'] = '18';
arrows['arrow_198.png']['height'] = '23';
arrows['arrow_199.png'] = []
arrows['arrow_199.png']['width'] = '18';
arrows['arrow_199.png']['height'] = '23';
arrows['arrow_200.png'] = []
arrows['arrow_200.png']['width'] = '18';
arrows['arrow_200.png']['height'] = '23';
arrows['arrow_201.png'] = []
arrows['arrow_201.png']['width'] = '18';
arrows['arrow_201.png']['height'] = '23';
arrows['arrow_202.png'] = []
arrows['arrow_202.png']['width'] = '19';
arrows['arrow_202.png']['height'] = '23';
arrows['arrow_203.png'] = []
arrows['arrow_203.png']['width'] = '19';
arrows['arrow_203.png']['height'] = '23';
arrows['arrow_204.png'] = []
arrows['arrow_204.png']['width'] = '19';
arrows['arrow_204.png']['height'] = '23';
arrows['arrow_205.png'] = []
arrows['arrow_205.png']['width'] = '19';
arrows['arrow_205.png']['height'] = '23';
arrows['arrow_206.png'] = []
arrows['arrow_206.png']['width'] = '20';
arrows['arrow_206.png']['height'] = '23';
arrows['arrow_207.png'] = []
arrows['arrow_207.png']['width'] = '20';
arrows['arrow_207.png']['height'] = '23';
arrows['arrow_208.png'] = []
arrows['arrow_208.png']['width'] = '20';
arrows['arrow_208.png']['height'] = '23';
arrows['arrow_209.png'] = []
arrows['arrow_209.png']['width'] = '20';
arrows['arrow_209.png']['height'] = '23';
arrows['arrow_210.png'] = []
arrows['arrow_210.png']['width'] = '20';
arrows['arrow_210.png']['height'] = '23';
arrows['arrow_211.png'] = []
arrows['arrow_211.png']['width'] = '21';
arrows['arrow_211.png']['height'] = '23';
arrows['arrow_212.png'] = []
arrows['arrow_212.png']['width'] = '21';
arrows['arrow_212.png']['height'] = '23';
arrows['arrow_213.png'] = []
arrows['arrow_213.png']['width'] = '21';
arrows['arrow_213.png']['height'] = '23';
arrows['arrow_214.png'] = []
arrows['arrow_214.png']['width'] = '21';
arrows['arrow_214.png']['height'] = '23';
arrows['arrow_215.png'] = []
arrows['arrow_215.png']['width'] = '21';
arrows['arrow_215.png']['height'] = '23';
arrows['arrow_216.png'] = []
arrows['arrow_216.png']['width'] = '21';
arrows['arrow_216.png']['height'] = '23';
arrows['arrow_217.png'] = []
arrows['arrow_217.png']['width'] = '22';
arrows['arrow_217.png']['height'] = '23';
arrows['arrow_218.png'] = []
arrows['arrow_218.png']['width'] = '22';
arrows['arrow_218.png']['height'] = '23';
arrows['arrow_219.png'] = []
arrows['arrow_219.png']['width'] = '22';
arrows['arrow_219.png']['height'] = '23';
arrows['arrow_220.png'] = []
arrows['arrow_220.png']['width'] = '22';
arrows['arrow_220.png']['height'] = '23';
arrows['arrow_221.png'] = []
arrows['arrow_221.png']['width'] = '22';
arrows['arrow_221.png']['height'] = '23';
arrows['arrow_222.png'] = []
arrows['arrow_222.png']['width'] = '22';
arrows['arrow_222.png']['height'] = '23';
arrows['arrow_223.png'] = []
arrows['arrow_223.png']['width'] = '22';
arrows['arrow_223.png']['height'] = '23';
arrows['arrow_224.png'] = []
arrows['arrow_224.png']['width'] = '23';
arrows['arrow_224.png']['height'] = '23';
arrows['arrow_225.png'] = []
arrows['arrow_225.png']['width'] = '23';
arrows['arrow_225.png']['height'] = '23';
arrows['arrow_226.png'] = []
arrows['arrow_226.png']['width'] = '23';
arrows['arrow_226.png']['height'] = '23';
arrows['arrow_227.png'] = []
arrows['arrow_227.png']['width'] = '23';
arrows['arrow_227.png']['height'] = '22';
arrows['arrow_228.png'] = []
arrows['arrow_228.png']['width'] = '23';
arrows['arrow_228.png']['height'] = '22';
arrows['arrow_229.png'] = []
arrows['arrow_229.png']['width'] = '23';
arrows['arrow_229.png']['height'] = '22';
arrows['arrow_230.png'] = []
arrows['arrow_230.png']['width'] = '23';
arrows['arrow_230.png']['height'] = '22';
arrows['arrow_231.png'] = []
arrows['arrow_231.png']['width'] = '23';
arrows['arrow_231.png']['height'] = '22';
arrows['arrow_232.png'] = []
arrows['arrow_232.png']['width'] = '23';
arrows['arrow_232.png']['height'] = '22';
arrows['arrow_233.png'] = []
arrows['arrow_233.png']['width'] = '23';
arrows['arrow_233.png']['height'] = '22';
arrows['arrow_234.png'] = []
arrows['arrow_234.png']['width'] = '23';
arrows['arrow_234.png']['height'] = '21';
arrows['arrow_235.png'] = []
arrows['arrow_235.png']['width'] = '23';
arrows['arrow_235.png']['height'] = '21';
arrows['arrow_236.png'] = []
arrows['arrow_236.png']['width'] = '23';
arrows['arrow_236.png']['height'] = '21';
arrows['arrow_237.png'] = []
arrows['arrow_237.png']['width'] = '23';
arrows['arrow_237.png']['height'] = '21';
arrows['arrow_238.png'] = []
arrows['arrow_238.png']['width'] = '23';
arrows['arrow_238.png']['height'] = '21';
arrows['arrow_239.png'] = []
arrows['arrow_239.png']['width'] = '23';
arrows['arrow_239.png']['height'] = '21';
arrows['arrow_240.png'] = []
arrows['arrow_240.png']['width'] = '23';
arrows['arrow_240.png']['height'] = '20';
arrows['arrow_241.png'] = []
arrows['arrow_241.png']['width'] = '23';
arrows['arrow_241.png']['height'] = '20';
arrows['arrow_242.png'] = []
arrows['arrow_242.png']['width'] = '23';
arrows['arrow_242.png']['height'] = '20';
arrows['arrow_243.png'] = []
arrows['arrow_243.png']['width'] = '23';
arrows['arrow_243.png']['height'] = '20';
arrows['arrow_244.png'] = []
arrows['arrow_244.png']['width'] = '23';
arrows['arrow_244.png']['height'] = '20';
arrows['arrow_245.png'] = []
arrows['arrow_245.png']['width'] = '23';
arrows['arrow_245.png']['height'] = '19';
arrows['arrow_246.png'] = []
arrows['arrow_246.png']['width'] = '23';
arrows['arrow_246.png']['height'] = '19';
arrows['arrow_247.png'] = []
arrows['arrow_247.png']['width'] = '23';
arrows['arrow_247.png']['height'] = '19';
arrows['arrow_248.png'] = []
arrows['arrow_248.png']['width'] = '23';
arrows['arrow_248.png']['height'] = '19';
arrows['arrow_249.png'] = []
arrows['arrow_249.png']['width'] = '23';
arrows['arrow_249.png']['height'] = '18';
arrows['arrow_250.png'] = []
arrows['arrow_250.png']['width'] = '23';
arrows['arrow_250.png']['height'] = '18';
arrows['arrow_251.png'] = []
arrows['arrow_251.png']['width'] = '23';
arrows['arrow_251.png']['height'] = '18';
arrows['arrow_252.png'] = []
arrows['arrow_252.png']['width'] = '23';
arrows['arrow_252.png']['height'] = '18';
arrows['arrow_253.png'] = []
arrows['arrow_253.png']['width'] = '23';
arrows['arrow_253.png']['height'] = '17';
arrows['arrow_254.png'] = []
arrows['arrow_254.png']['width'] = '23';
arrows['arrow_254.png']['height'] = '17';
arrows['arrow_255.png'] = []
arrows['arrow_255.png']['width'] = '22';
arrows['arrow_255.png']['height'] = '17';
arrows['arrow_256.png'] = []
arrows['arrow_256.png']['width'] = '22';
arrows['arrow_256.png']['height'] = '16';
arrows['arrow_257.png'] = []
arrows['arrow_257.png']['width'] = '22';
arrows['arrow_257.png']['height'] = '16';
arrows['arrow_258.png'] = []
arrows['arrow_258.png']['width'] = '22';
arrows['arrow_258.png']['height'] = '16';
arrows['arrow_259.png'] = []
arrows['arrow_259.png']['width'] = '22';
arrows['arrow_259.png']['height'] = '16';
arrows['arrow_260.png'] = []
arrows['arrow_260.png']['width'] = '22';
arrows['arrow_260.png']['height'] = '15';
arrows['arrow_261.png'] = []
arrows['arrow_261.png']['width'] = '22';
arrows['arrow_261.png']['height'] = '15';
arrows['arrow_262.png'] = []
arrows['arrow_262.png']['width'] = '21';
arrows['arrow_262.png']['height'] = '15';
arrows['arrow_263.png'] = []
arrows['arrow_263.png']['width'] = '21';
arrows['arrow_263.png']['height'] = '14';
arrows['arrow_264.png'] = []
arrows['arrow_264.png']['width'] = '21';
arrows['arrow_264.png']['height'] = '14';
arrows['arrow_265.png'] = []
arrows['arrow_265.png']['width'] = '21';
arrows['arrow_265.png']['height'] = '14';
arrows['arrow_266.png'] = []
arrows['arrow_266.png']['width'] = '21';
arrows['arrow_266.png']['height'] = '13';
arrows['arrow_267.png'] = []
arrows['arrow_267.png']['width'] = '21';
arrows['arrow_267.png']['height'] = '13';
arrows['arrow_268.png'] = []
arrows['arrow_268.png']['width'] = '20';
arrows['arrow_268.png']['height'] = '13';
arrows['arrow_269.png'] = []
arrows['arrow_269.png']['width'] = '20';
arrows['arrow_269.png']['height'] = '12';
arrows['arrow_270.png'] = []
arrows['arrow_270.png']['width'] = '20';
arrows['arrow_270.png']['height'] = '12';
arrows['arrow_271.png'] = []
arrows['arrow_271.png']['width'] = '20';
arrows['arrow_271.png']['height'] = '12';
arrows['arrow_272.png'] = []
arrows['arrow_272.png']['width'] = '20';
arrows['arrow_272.png']['height'] = '13';
arrows['arrow_273.png'] = []
arrows['arrow_273.png']['width'] = '21';
arrows['arrow_273.png']['height'] = '13';
arrows['arrow_274.png'] = []
arrows['arrow_274.png']['width'] = '21';
arrows['arrow_274.png']['height'] = '13';
arrows['arrow_275.png'] = []
arrows['arrow_275.png']['width'] = '21';
arrows['arrow_275.png']['height'] = '14';
arrows['arrow_276.png'] = []
arrows['arrow_276.png']['width'] = '21';
arrows['arrow_276.png']['height'] = '14';
arrows['arrow_277.png'] = []
arrows['arrow_277.png']['width'] = '21';
arrows['arrow_277.png']['height'] = '14';
arrows['arrow_278.png'] = []
arrows['arrow_278.png']['width'] = '21';
arrows['arrow_278.png']['height'] = '15';
arrows['arrow_279.png'] = []
arrows['arrow_279.png']['width'] = '22';
arrows['arrow_279.png']['height'] = '15';
arrows['arrow_280.png'] = []
arrows['arrow_280.png']['width'] = '22';
arrows['arrow_280.png']['height'] = '15';
arrows['arrow_281.png'] = []
arrows['arrow_281.png']['width'] = '22';
arrows['arrow_281.png']['height'] = '16';
arrows['arrow_282.png'] = []
arrows['arrow_282.png']['width'] = '22';
arrows['arrow_282.png']['height'] = '16';
arrows['arrow_283.png'] = []
arrows['arrow_283.png']['width'] = '22';
arrows['arrow_283.png']['height'] = '16';
arrows['arrow_284.png'] = []
arrows['arrow_284.png']['width'] = '22';
arrows['arrow_284.png']['height'] = '16';
arrows['arrow_285.png'] = []
arrows['arrow_285.png']['width'] = '22';
arrows['arrow_285.png']['height'] = '17';
arrows['arrow_286.png'] = []
arrows['arrow_286.png']['width'] = '23';
arrows['arrow_286.png']['height'] = '17';
arrows['arrow_287.png'] = []
arrows['arrow_287.png']['width'] = '23';
arrows['arrow_287.png']['height'] = '17';
arrows['arrow_288.png'] = []
arrows['arrow_288.png']['width'] = '23';
arrows['arrow_288.png']['height'] = '18';
arrows['arrow_289.png'] = []
arrows['arrow_289.png']['width'] = '23';
arrows['arrow_289.png']['height'] = '18';
arrows['arrow_290.png'] = []
arrows['arrow_290.png']['width'] = '23';
arrows['arrow_290.png']['height'] = '18';
arrows['arrow_291.png'] = []
arrows['arrow_291.png']['width'] = '23';
arrows['arrow_291.png']['height'] = '18';
arrows['arrow_292.png'] = []
arrows['arrow_292.png']['width'] = '23';
arrows['arrow_292.png']['height'] = '19';
arrows['arrow_293.png'] = []
arrows['arrow_293.png']['width'] = '23';
arrows['arrow_293.png']['height'] = '19';
arrows['arrow_294.png'] = []
arrows['arrow_294.png']['width'] = '23';
arrows['arrow_294.png']['height'] = '19';
arrows['arrow_295.png'] = []
arrows['arrow_295.png']['width'] = '23';
arrows['arrow_295.png']['height'] = '19';
arrows['arrow_296.png'] = []
arrows['arrow_296.png']['width'] = '23';
arrows['arrow_296.png']['height'] = '20';
arrows['arrow_297.png'] = []
arrows['arrow_297.png']['width'] = '23';
arrows['arrow_297.png']['height'] = '20';
arrows['arrow_298.png'] = []
arrows['arrow_298.png']['width'] = '23';
arrows['arrow_298.png']['height'] = '20';
arrows['arrow_299.png'] = []
arrows['arrow_299.png']['width'] = '23';
arrows['arrow_299.png']['height'] = '20';
arrows['arrow_300.png'] = []
arrows['arrow_300.png']['width'] = '23';
arrows['arrow_300.png']['height'] = '20';
arrows['arrow_301.png'] = []
arrows['arrow_301.png']['width'] = '23';
arrows['arrow_301.png']['height'] = '21';
arrows['arrow_302.png'] = []
arrows['arrow_302.png']['width'] = '23';
arrows['arrow_302.png']['height'] = '21';
arrows['arrow_303.png'] = []
arrows['arrow_303.png']['width'] = '23';
arrows['arrow_303.png']['height'] = '21';
arrows['arrow_304.png'] = []
arrows['arrow_304.png']['width'] = '23';
arrows['arrow_304.png']['height'] = '21';
arrows['arrow_305.png'] = []
arrows['arrow_305.png']['width'] = '23';
arrows['arrow_305.png']['height'] = '21';
arrows['arrow_306.png'] = []
arrows['arrow_306.png']['width'] = '23';
arrows['arrow_306.png']['height'] = '21';
arrows['arrow_307.png'] = []
arrows['arrow_307.png']['width'] = '23';
arrows['arrow_307.png']['height'] = '22';
arrows['arrow_308.png'] = []
arrows['arrow_308.png']['width'] = '23';
arrows['arrow_308.png']['height'] = '22';
arrows['arrow_309.png'] = []
arrows['arrow_309.png']['width'] = '23';
arrows['arrow_309.png']['height'] = '22';
arrows['arrow_310.png'] = []
arrows['arrow_310.png']['width'] = '23';
arrows['arrow_310.png']['height'] = '22';
arrows['arrow_311.png'] = []
arrows['arrow_311.png']['width'] = '23';
arrows['arrow_311.png']['height'] = '22';
arrows['arrow_312.png'] = []
arrows['arrow_312.png']['width'] = '23';
arrows['arrow_312.png']['height'] = '22';
arrows['arrow_313.png'] = []
arrows['arrow_313.png']['width'] = '23';
arrows['arrow_313.png']['height'] = '22';
arrows['arrow_314.png'] = []
arrows['arrow_314.png']['width'] = '23';
arrows['arrow_314.png']['height'] = '23';
arrows['arrow_315.png'] = []
arrows['arrow_315.png']['width'] = '23';
arrows['arrow_315.png']['height'] = '23';
arrows['arrow_316.png'] = []
arrows['arrow_316.png']['width'] = '23';
arrows['arrow_316.png']['height'] = '23';
arrows['arrow_317.png'] = []
arrows['arrow_317.png']['width'] = '22';
arrows['arrow_317.png']['height'] = '23';
arrows['arrow_318.png'] = []
arrows['arrow_318.png']['width'] = '22';
arrows['arrow_318.png']['height'] = '23';
arrows['arrow_319.png'] = []
arrows['arrow_319.png']['width'] = '22';
arrows['arrow_319.png']['height'] = '23';
arrows['arrow_320.png'] = []
arrows['arrow_320.png']['width'] = '22';
arrows['arrow_320.png']['height'] = '23';
arrows['arrow_321.png'] = []
arrows['arrow_321.png']['width'] = '22';
arrows['arrow_321.png']['height'] = '23';
arrows['arrow_322.png'] = []
arrows['arrow_322.png']['width'] = '22';
arrows['arrow_322.png']['height'] = '23';
arrows['arrow_323.png'] = []
arrows['arrow_323.png']['width'] = '22';
arrows['arrow_323.png']['height'] = '23';
arrows['arrow_324.png'] = []
arrows['arrow_324.png']['width'] = '21';
arrows['arrow_324.png']['height'] = '23';
arrows['arrow_325.png'] = []
arrows['arrow_325.png']['width'] = '21';
arrows['arrow_325.png']['height'] = '23';
arrows['arrow_326.png'] = []
arrows['arrow_326.png']['width'] = '21';
arrows['arrow_326.png']['height'] = '23';
arrows['arrow_327.png'] = []
arrows['arrow_327.png']['width'] = '21';
arrows['arrow_327.png']['height'] = '23';
arrows['arrow_328.png'] = []
arrows['arrow_328.png']['width'] = '21';
arrows['arrow_328.png']['height'] = '23';
arrows['arrow_329.png'] = []
arrows['arrow_329.png']['width'] = '21';
arrows['arrow_329.png']['height'] = '23';
arrows['arrow_330.png'] = []
arrows['arrow_330.png']['width'] = '20';
arrows['arrow_330.png']['height'] = '23';
arrows['arrow_331.png'] = []
arrows['arrow_331.png']['width'] = '20';
arrows['arrow_331.png']['height'] = '23';
arrows['arrow_332.png'] = []
arrows['arrow_332.png']['width'] = '20';
arrows['arrow_332.png']['height'] = '23';
arrows['arrow_333.png'] = []
arrows['arrow_333.png']['width'] = '20';
arrows['arrow_333.png']['height'] = '23';
arrows['arrow_334.png'] = []
arrows['arrow_334.png']['width'] = '20';
arrows['arrow_334.png']['height'] = '23';
arrows['arrow_335.png'] = []
arrows['arrow_335.png']['width'] = '19';
arrows['arrow_335.png']['height'] = '23';
arrows['arrow_336.png'] = []
arrows['arrow_336.png']['width'] = '19';
arrows['arrow_336.png']['height'] = '23';
arrows['arrow_337.png'] = []
arrows['arrow_337.png']['width'] = '19';
arrows['arrow_337.png']['height'] = '23';
arrows['arrow_338.png'] = []
arrows['arrow_338.png']['width'] = '19';
arrows['arrow_338.png']['height'] = '23';
arrows['arrow_339.png'] = []
arrows['arrow_339.png']['width'] = '18';
arrows['arrow_339.png']['height'] = '23';
arrows['arrow_340.png'] = []
arrows['arrow_340.png']['width'] = '18';
arrows['arrow_340.png']['height'] = '23';
arrows['arrow_341.png'] = []
arrows['arrow_341.png']['width'] = '18';
arrows['arrow_341.png']['height'] = '23';
arrows['arrow_342.png'] = []
arrows['arrow_342.png']['width'] = '18';
arrows['arrow_342.png']['height'] = '23';
arrows['arrow_343.png'] = []
arrows['arrow_343.png']['width'] = '17';
arrows['arrow_343.png']['height'] = '23';
arrows['arrow_344.png'] = []
arrows['arrow_344.png']['width'] = '17';
arrows['arrow_344.png']['height'] = '23';
arrows['arrow_345.png'] = []
arrows['arrow_345.png']['width'] = '17';
arrows['arrow_345.png']['height'] = '22';
arrows['arrow_346.png'] = []
arrows['arrow_346.png']['width'] = '16';
arrows['arrow_346.png']['height'] = '22';
arrows['arrow_347.png'] = []
arrows['arrow_347.png']['width'] = '16';
arrows['arrow_347.png']['height'] = '22';
arrows['arrow_348.png'] = []
arrows['arrow_348.png']['width'] = '16';
arrows['arrow_348.png']['height'] = '22';
arrows['arrow_349.png'] = []
arrows['arrow_349.png']['width'] = '16';
arrows['arrow_349.png']['height'] = '22';
arrows['arrow_350.png'] = []
arrows['arrow_350.png']['width'] = '15';
arrows['arrow_350.png']['height'] = '22';
arrows['arrow_351.png'] = []
arrows['arrow_351.png']['width'] = '15';
arrows['arrow_351.png']['height'] = '22';
arrows['arrow_352.png'] = []
arrows['arrow_352.png']['width'] = '15';
arrows['arrow_352.png']['height'] = '21';
arrows['arrow_353.png'] = []
arrows['arrow_353.png']['width'] = '14';
arrows['arrow_353.png']['height'] = '21';
arrows['arrow_354.png'] = []
arrows['arrow_354.png']['width'] = '14';
arrows['arrow_354.png']['height'] = '21';
arrows['arrow_355.png'] = []
arrows['arrow_355.png']['width'] = '14';
arrows['arrow_355.png']['height'] = '21';
arrows['arrow_356.png'] = []
arrows['arrow_356.png']['width'] = '13';
arrows['arrow_356.png']['height'] = '21';
arrows['arrow_357.png'] = []
arrows['arrow_357.png']['width'] = '13';
arrows['arrow_357.png']['height'] = '21';
arrows['arrow_358.png'] = []
arrows['arrow_358.png']['width'] = '13';
arrows['arrow_358.png']['height'] = '20';
arrows['arrow_359.png'] = []
arrows['arrow_359.png']['width'] = '12';
arrows['arrow_359.png']['height'] = '20';
arrows['arrow_360.png'] = []
arrows['arrow_360.png']['width'] = '12';
arrows['arrow_360.png']['height'] = '20';
